<template>
  <div>
    <!-- Body -->
    <div class="fd-app-wrap">
      <div class="container p-4">
        <div
          class="jumbotron"
          style="background-color: #f8f8f8"
        >
          <h1 class="display-4">
            Documentação
          </h1>
          <p class="lead">
            Informações sobre API de integração.
          </p>

          <b-card no-body>
            <b-tabs card>
              <b-tab
                title="SSO (Single Sign-On)"
                active
              >
                <b-card-text>
                  <div
                    id="sso"
                    class="p-4"
                  >
                    <h3>API FastEAD SSO</h3>
                    <p>A API Fastead possibilita que seu aluno tenha acesso à plataforma de forma transparente, sem a necessidade de preenchimento de formulário de cadastro.</p>
                    <hr class="my-4">

                    <h3>Padrão utilizado</h3>
                    <p>Os servidores API utilizam o protocolo HTTPS, o padrão REST e o formato JSON API codificado em UTF-8 para expor os recursos disponíveis.</p>
                    <hr class="my-4">

                    <h3>Parâmetros utilizados</h3>
                    <p>Alguns parâmetros são utilizados no retorno da comunicação com a API:</p>
                    <ul class="list-group">
                      <li class="list-group-item">
                        <b>Status:</b> estado da autenticação (true/false).
                      </li>
                      <li class="list-group-item">
                        <b>Id_usuario:</b> identificador do usuário no sistema FastEAD.
                      </li>
                      <li class="list-group-item">
                        <b>Id_plataforma:</b> identificador da plataforma.
                      </li>
                      <li class="list-group-item">
                        <b>Sso_url:</b> URL com token que dará acesso à plataforma.
                      </li>
                      <li class="list-group-item">
                        <b>Error:</b> Informações de erro.
                      </li>
                    </ul>
                    <hr class="my-4">
                    <div
                      class="alert alert-info"
                      role="alert"
                    >
                      <h3>1. Processo de registro</h3>
                      <p>Para registrar um aluno em sua plataforma você deve enviar uma requisição contendo seu Token e algumas informações básicas.</p>

                      <ul class="list-group mt-4 mb-4">
                        <li class="list-group-item">
                          <b>URL:</b> https://api.fastead.com/api/fast_plataforma_usuario/ssoRegister
                        </li>
                      </ul>
                      <p>Exemplo de requisição via POST:</p>
                      <img
                        :src="require('@/assets/images/sso_register.png')"
                        class="img-fluid"
                        alt="Registro na API"
                      >

                      <p class="mt-4">
                        Exemplo de resposta:
                      </p>
                      <img
                        :src="require('@/assets/images/sso_register_response.png')"
                        class="img-fluid"
                        alt="Registro na API"
                      >
                    </div>
                    <hr class="my-4">
                    <div
                      class="alert alert-info"
                      role="alert"
                    >
                      <h3>2. Processo de autenticação</h3>
                      <p>Uma vez registrado para autenticar o aluno é necessário informar apenas seu TOKEN e o email do aluno.</p>

                      <ul class="list-group mt-4 mb-4">
                        <li class="list-group-item">
                          <b>URL:</b> https://api.fastead.com/api/fast_plataforma_usuario/ssoAuth
                        </li>
                      </ul>
                      <p>Exemplo de requisição via POST:</p>
                      <img
                        :src="require('@/assets/images/sso_auth.png')"
                        class="img-fluid"
                        alt="Registro na API"
                      >

                      <p class="mt-4">
                        Exemplo de resposta:
                      </p>
                      <img
                        :src="require('@/assets/images/sso_register_response.png')"
                        class="img-fluid"
                        alt="Registro na API"
                      >
                    </div>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab title="Gateway externo de pagamento">
                <b-card-text>
                  <div
                    id="checkout"
                    class="p-4"
                  >
                    <h3>API FastEAD para gateway externo de pagamento</h3>
                    <p>É possível enviar requisições para a API de confirmações de pagamento, permitindo que cliente seja automaticamente inserido na plataforma com os cursos disponíveis.</p>
                    <hr class="my-4">

                    <h3>Padrão utilizado</h3>
                    <p>Os servidores API utilizam o protocolo HTTPS, o padrão REST e o formato JSON API codificado em UTF-8 para expor os recursos disponíveis.</p>
                    <hr class="my-4">

                    <h3>Parâmetros utilizados</h3>
                    <p>Alguns parâmetros são utilizados no retorno da comunicação com a API:</p>
                    <ul class="list-group">
                      <li class="list-group-item">
                        <b>Status:</b> estado da autenticação (true/false).
                      </li>
                      <li class="list-group-item">
                        <b>Id_usuario:</b> identificador do usuário no sistema FastEAD.
                      </li>
                      <li class="list-group-item">
                        <b>Id_plataforma:</b> identificador da plataforma.
                      </li>
                      <li class="list-group-item">
                        <b>Error:</b> Informações de erro.
                      </li>
                    </ul>
                    <hr class="my-4">
                    <div
                      class="alert alert-info"
                      role="alert"
                    >
                      <h3>1. Envio da requisição de confirmação de pagamento</h3>
                      <p>Para confirmar o pagamento envie uma requisição para API contendo seu Token de Checkout, informações do cliente e os identificadores dos cursos comprados.</p>

                      <ul class="list-group mt-4 mb-4">
                        <li class="list-group-item">
                          <b>URL:</b> https://api.fastead.com/api/fast_plataforma_usuario/ssoCheckout
                        </li>
                      </ul>
                      <p>Exemplo de requisição via POST:</p>
                      <img
                        :src="require('@/assets/images/sso_checkout.png')"
                        class="img-fluid"
                        alt="Registro na API"
                      >

                      <p class="mt-4">
                        Exemplo de resposta:
                      </p>
                      <img
                        :src="require('@/assets/images/sso_checkout_response.png')"
                        class="img-fluid"
                        alt="Registro na API"
                      >

                      <p class="mt-4">
                        Caso a resposta sejá TRUE o cliente receberá um email de confirmação de pagamento e um link para ingressar na plataforma.
                      </p>
                    </div>
                  </div>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeExternoDeveloper",
  mixins: [methods],
  data: function() {
    return {};
  },
  mounted() {
    this.$store.state.fastCarregando = false;
  },
  methods: {},
};
</script>

<style scoped></style>
